import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Microsoft håvar in npm: `}<a parentName="li" {...{
          "href": "https://github.blog/2020-03-16-npm-is-joining-github/"
        }}>{`https://github.blog/2020-03-16-npm-is-joining-github/`}</a></li>
      <li parentName="ul">{`Authy beta till Linux (tack Christer): `}<a parentName="li" {...{
          "href": "https://authy.com/blog/authy-desktop-now-available-in-beta-for-linux/"
        }}>{`https://authy.com/blog/authy-desktop-now-available-in-beta-for-linux/`}</a></li>
      <li parentName="ul">{`Firefox ångrar sig om TLS 1.0/1.1: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/74.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/74.0/releasenotes/`}</a></li>
      <li parentName="ul">{`Coronaviruset pressar internet: `}<a parentName="li" {...{
          "href": "https://blog.cloudflare.com/on-the-shoulders-of-giants-recent-changes-in-internet-traffic/"
        }}>{`https://blog.cloudflare.com/on-the-shoulders-of-giants-recent-changes-in-internet-traffic/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Nya Edge sämst på privacy?: `}<a parentName="li" {...{
          "href": "https://www.scss.tcd.ie/Doug.Leith/pubs/browser_privacy.pdf"
        }}>{`https://www.scss.tcd.ie/Doug.Leith/pubs/browser_privacy.pdf`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Flameshot: `}<a parentName="li" {...{
          "href": "https://flameshot.js.org"
        }}>{`https://flameshot.js.org`}</a></li>
      <li parentName="ul">{`Worldwide hackathon för bristfällig utrustning:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.coventchallenge.com/"
            }}>{`https://www.coventchallenge.com/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Intet nytt, men Anbox gör framsteg`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Alex testade PoE lite snabbt`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Lars tipsar om Privacy Badger (webbläsartillägg)`}</li>
      <li parentName="ul">{`Kvartalsbrejk, ses igen 6e april`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      